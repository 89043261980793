// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/builds/neaufmaC/0/lf/saas-eco/upselling-admin/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/pages/loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__index' */'@/layouts/index.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/adCard",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__adCard__index' */'@/pages/adCard/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/anotherOrder",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__anotherOrder__index' */'@/pages/anotherOrder/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__index' */'@/pages/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/loading",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__loading__index' */'@/pages/loading/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/productCard/context/previewData",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__productCard__context__previewData' */'@/pages/productCard/context/previewData.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/productCard/context/previewMode",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__productCard__context__previewMode' */'@/pages/productCard/context/previewMode.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/productCard/context/verticalWrapperSize",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__productCard__context__verticalWrapperSize' */'@/pages/productCard/context/verticalWrapperSize.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/productCard",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__productCard__index' */'@/pages/productCard/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/recommendationProducts",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__recommendationProducts__index' */'@/pages/recommendationProducts/index.tsx'), loading: LoadingComponent})
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
